.Notifications{
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.Notifications-message{
  background: #5a6169;
  color: #fff;
  padding: .5em 1em;
  border-radius: 5px;
  box-shadow: 0 0 .15em rgba(0,0,0,0.25), 0 0 .5em rgba(0,0,0,0.15);
}

.Notifications-close{
  color: #000;
  text-shadow: -1px 0 #999;
}