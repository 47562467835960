@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loading{
  text-align: center;
}
 
.loading-animation {
  box-sizing: border-box;
  position: relative;
  display: block;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}

.loading-animation--small {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
}

.loadingPage{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}