.TableFilter{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2em 0 0;
}

.TableFilter-actions{
  display: flex;

}

.TableFilter-group{
  display: flex;
  margin-bottom: 0;
  align-items: baseline;
}

.TableFilter-group > *{
  margin-right: 10px;
}

.TableFilter-search{
  width: 400px;
  display: flex;
}

.TableFilter-search > *{
  margin-left: 10px;
}

.TableFilter-count{
  margin-bottom: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.TableFilter-actions .btn{
  margin-right: 5px;
}

.table-row-actions{
  opacity: 0;
  transition: opacity 0.3s;
}

.table-row:hover .table-row-actions{
  opacity: 1;
}