body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-name{
  margin-left: 1.5625rem;
}

.card-title{
  border-bottom: 1px solid #cbd3e0;
}

.table-row-actions:hover{
  opacity: 1;
}

.table td{
  vertical-align: middle;
}

.page-title + .badge{
  margin-left: 1em;
}

.Profile-photo{
  height: 5em;
}

.Profile-meta + .Profile-meta{
  margin-left: 10px;
  border-left: 1px solid #cbd3e0;
  padding-left: 10px;
}

.Profile-GroupTitle{
  border-bottom: 1px solid #cbd3e0;
  padding-bottom: .25em;
  font-size: 1.25em;
  font-weight: 300;
  text-transform: uppercase;
}

.Profile-Property + .Profile-GroupTitle{
  margin-top: 1em;
}

.Profile-SectionTitle{
  font-size: 1em;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: .5em;
  margin-top: 2em;
  line-height: 1;
}

.Profile-SectionTitle:before{
  content: " ";
  display: inline-block;
  width: 5px;
  height: .75em;
  background: #3d5170;
  opacity: 0.3;
  margin-right: 10px;
}

.Profile-Property{
  background: #fff;
  padding: 5px;
  margin-left: -5px;
  border-radius: 5px;
  display: flex;
  justify-content: stretch;
}

.Profile-Property i{
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
  background: rgba(200,200,200,0);
  transition: 0.2s background;
}

.Profile-Property i:hover{
  background: rgba(180,180,180,0.5);
  color: #007bff;
}

.Profile-PropertyTitle{
  font-size: 1rem;
  font-weight: 700;
  flex: 0 0 10em;
}

.Profile-PropertyValue{
  word-break: break-all;
}

.button-refresh{
  margin-left: 10px;
}

.usersearch{
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
}

.usersearch table{
  table-layout: fixed;
}

.usersearch td{
  vertical-align: middle;
}

.usersearch td:last-child{
  text-align: right;
}

.usersearch-classyear{
  width: 4.5em;
}

.usersearch-match{
  width: 6.5em;
}